/* eslint-disable @typescript-eslint/no-explicit-any */
import Workflow from './base';

class Member extends Workflow {
  constructor() {
    super('memberwf');
  }

  async GetAffiliationAndClubsInfo(individualId: string) {
    this.setAction('GetAffiliationAndClubsInfo');
    this.setData({
      individualId,
    });

    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get club and district  info');
    }
  }

  async ClubMembersSearch(
    pageNumber: number,
    pageSize: number,
    data: Record<string, any>
  ) {
    this.setAction('clubMembersSearch');
    this.setData({
      pageNumber,
      pageSize,
      searchAndFilterData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get club member search  info');
    }
  }

  async DistrictMembersSearch(
    pageNumber: number,
    pageSize: number,
    data: Record<string, any>
  ) {
    this.setAction('districtMembersSearch');
    this.setData({
      pageNumber,
      pageSize,
      searchAndFilterData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get district member search  info');
    }
  }

  async DistrictClubsSearch(
    pageNumber: number,
    pageSize: number,
    data: Record<string, any>
  ) {
    this.setAction('districtClubsSearch');
    this.setData({
      pageNumber,
      pageSize,
      searchAndFilterData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get district club member search  info');
    }
  }

  async AddMembersSearch(
    pageNumber: number,
    pageSize: number,
    data: Record<string, any>
  ) {
    this.setAction('addMembersSearch');
    this.setData({
      pageNumber,
      pageSize,
      searchMemberData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get add search member info.');
    }
  }

  async FetchExistingEmail(data: Record<string, any>) {
    this.setAction('fetchExistingEmail');
    this.setData({
      putData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch existing mail info.');
    }
  }

  async fetchPossibleSponsors(data: Record<string, any>) {
    this.setAction('fetchPossibleSponsors');
    this.setData({
      putData: data,
    });
    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch possible sponsors info.');
    }
  }

  async AddMemberToClub(data: Record<string, any>) {
    this.setAction('addMemberToClub');
    this.setData({
      putData: data,
    });

    try {
      const res = await this.postCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to  add  member to club.');
    }
  }
}

export default Member;
